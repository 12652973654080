import { Component, OnInit } from '@angular/core';
declare var $: any;
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading.service';
import { ToastrService } from 'ngx-toastr';

import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DashboardService } from '../../services/dashboard.service';
import { BackendService } from '../../services/backendservice.service';
import { UserDataService } from '../../services/user-data.service'


@Component({
  selector: 'cxr-call-copy',
  templateUrl: './call-copy.component.html',
  styleUrls: ['./call-copy.component.scss']
})
export class CallCopyComponent implements OnInit {

  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period";
  dateDisplay = false;
  calls = [];
  campaigns = [];
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  dateRange = [];

  isCallLoading = true;

  campaignId;
  cloudText;
  status;
  projectId;
  focusKeyword;
  callSectionName;

  start_date:any;
  end_date:any;

  selectedFilter;
  total_agents : Set<number> = new Set();

  faildCalls = 0;
  worningCalls = 0;
  totalScore = 0;

  checksFilterModel = '';
  campaignsFilterModel = '';
  projectFilterModel = '';
  openingFilterModel = [];
  bodyFilterModel = [];
  closingFilterModel = [];
  customerFilterModel = [];
  agentFilterModel = [];
  callSectionFilterModel = '';
  projects: any = [];
  campaing: any = [];
  isProjectLoading = true;
  campaignsFilter:any;
  projectsFilter:any;
  callSection: any = [];
  topChangeFilterModel = 'Opening';
  nonCompliant: any = [];
  qualityCheck: any = [];

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }
  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private dashBoardService: DashboardService,
    private backendService: BackendService,
    private toastr: ToastrService,
    private userService: UserDataService,


  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.date != undefined && params.date != '') {
        let splittedDate = params.date.split('/');
        let formattedDate = splittedDate[1] + '/' + splittedDate[0]+ '/' + splittedDate[2];
        let nextDate = new Date(formattedDate);
        // nextDate.setDate(nextDate.getDate()+1);
        nextDate.setDate(nextDate.getDate());
        this.start_date = formattedDate;
        this.end_date = nextDate;

        this.dateRange.push((moment(this.start_date)).format('YYYY-MM-DD'));
        this.dateRange.push(moment(this.end_date).format('YYYY-MM-DD'));
      }
      if (params.start_date != undefined && params.start_date != '') {
        this.start_date = params.start_date;
        this.end_date = params.end_date;
        this.dateRange.push((moment(this.start_date)).format('YYYY-MM-DD'));
        this.dateRange.push(moment(this.end_date).format('YYYY-MM-DD'));
      }
      this.campaignId = params.campId;
      this.checksFilterModel = params.qualityCheck;
      this.callSectionName = params.callSectionName;
      this.cloudText = params.word;
      this.status = params.status;
      this.projectId = params.projectId;
      this.focusKeyword = params.focusKeyword;
    });

    this.toggleDate();

    if (this.dateRange.length == 0) {
      this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
      this.dateRange.push(moment().format('YYYY-MM-DD'));
      this.start_date = this.dateRange[0];
      this.end_date = this.dateRange[1];
    }

    this.setDate('today', '24 Hours');
        
  }
  


  display = false;
  setDate = (dateSelected, dateSelectedDisplay) => {
    this.toggleDate();
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    // this.refreshList(this.commonService.toDaysConvertor(this.selectedPeriod));
    this.refreshList();
    this.getAllProjects();
    this.getAllCampnaigns();
    this.getCallSection();
    this.getNonCompliantCall();
    // this.getQualityCheck();
  }

  redirectInternal = (id) => {
    this.router.navigateByUrl('/call/' + id);

  }

  toggleDisplay = () => {
    if (!this.display) {
      $('.actions').css('display', 'unset');
      this.display = true;
    } else {
      $('.actions').css('display', 'none');
      this.display = false;
    }
  }

  onProjectChange(id){
    // this.campaignsFilterModel = '';
    this.projectId = id == '' ? null : id;
    this.refreshList();
  }

  onCampaignChange(id){
    this.campaignId = id == '' ? null : id;
    // this.projectFilterModel = '';
    this.refreshList();
  }

  onCallSectionChange(id) {
    // console.log(this.openingFilterModel)
    let array = [];
    array = [ ,...this.openingFilterModel];
    array = array.concat(this.bodyFilterModel);
    array = array.concat(this.closingFilterModel);
    array = array.concat(this.customerFilterModel);
    array = array.concat(this.agentFilterModel);
    // console.log(array);
    
    let name = '';
    array.forEach((ele) => {
      if (name == '' && ele != '0') {
        name = ele;
      }
       else if (name != '' && ele != '0') {
        name = name + "," + ele;
      }
    });

    this.checksFilterModel = name == '' ? null : name;
    this.refreshList();
  }
  onChecksChange(id){
    // this.projectFilterModel = '';
    this.refreshList();
  }

  callSectionTopChange(name) {
    this.getNonCompliantCall();
  }
  resetFilter() {
    this.checksFilterModel = '';
    this.projectId = undefined;
    this.campaignId = undefined;
    this.campaignsFilterModel = '';
    this.projectFilterModel = '';
    this.selectedFilter = '';
    this.bodyFilterModel = [];
    this.openingFilterModel = [];
    this.closingFilterModel = [];
    this.customerFilterModel = [];
    this.agentFilterModel = [];
    this.refreshList();
    
  }

  refreshList() {
    let term = "";
    term = term + (this.campaignId != undefined ? ('&campId=' + this.campaignId) : '');
    term = term + (this.callSectionName != undefined ? ('&callsection=' + this.callSectionName) : '');
    term = term + ((this.cloudText != undefined && this.cloudText != '') ? ("&cloudText=" + this.cloudText) : "");
    term = term + (this.status != undefined ? ("&status=" + this.status) : "");
    term = term + (this.projectId != undefined ? ("&projectId=" + this.projectId) : "");
    term = term + (this.focusKeyword != undefined ? ("&focusKeyword=" + this.focusKeyword) : "");
    // term = term + (this.selectedFilter != undefined ? ("&filter=" + this.selectedFilter) : "");
    term = term + ((this.checksFilterModel != undefined && this.checksFilterModel != '') ? ("&qualityCheck=" + this.checksFilterModel) : "");

    this.totalScore = 0;
    this.faildCalls = 0;
    this.worningCalls = 0;

    this.isCallLoading = true;
    this.loadingService.apiStart();


    this.backendService.getCallsList(term, this.start_date, this.end_date).subscribe((data) => { 
      this.calls = data.calls;
      // console.log(this.calls)
      this.calls.forEach(call => {
        if (this.campaigns.indexOf(call.campaign_name) < 0) {
          this.campaigns.push(call.campaign_name);
        }

        // Total agent
        this.total_agents.add(call.agent_id);

        // Total QCS
        this.totalScore = this.totalScore + parseInt(call.quality_score);
        // console.log('totalScore',this.totalScore )
        if (call.quality_score < 0.25) {
        
          // failed calls
          this.faildCalls = this.faildCalls + 1;
        }else if (call.quality_score > 0.25 && call.quality_score < 0.37) {
        
          // WARNING calls
          this.worningCalls = this.worningCalls + 1;
        }else if (call.quality_score > 0.37) {
        
          // pass calls
          // this.totalScore = this.totalScore + 1;
        }
      });
      this.isCallLoading = false;
    
      this.loadingService.apiStop();

    })

    

    // this.commonService.getCalls(term, this.start_date, this.end_date)
    //   .toPromise()
    //   .then((data: any) => {
    //     this.loadingService.apiStop();
    //     this.calls = data.calls;
    //     this.calls.forEach(call => {
    //       if (this.campaigns.indexOf(call.campaign_name) < 0) {
    //         this.campaigns.push(call.campaign_name);
    //       }

    //       // Total agent
    //       this.total_agents.add(call.agent_id);

    //       // Total QCS
    //       // this.totalScore = this.totalScore + call.quality_score;
    //       if (call.quality_score < 0.5) {
    //         // failed calls
    //         this.faildCalls = this.faildCalls + 1;
    //       }else if (call.quality_score > 0.5 && call.quality_score < 0.7) {
    //         // WARNING calls 
    //         this.worningCalls = this.worningCalls + 1;
    //       }else if (call.quality_score > 0.7) {
    //         // pass calls 
    //         this.totalScore = this.totalScore + 1;
    //       }
    //     });
    //     this.isCallLoading = false;
    //   })
    //   .catch((err) => {
    //     this.isCallLoading = false;
    //     this.loadingService.apiStop();
    //   })

    
  }

  getNonCompliantCall() {
    let term = "";
    term = term + (this.topChangeFilterModel != undefined ? ('&callsection=' + this.topChangeFilterModel) : '');
    
    this.isProjectLoading = true;
    this.dashBoardService.getNonCompliantCall(term, this.start_date ,this.end_date)
    .toPromise()
    .then((data: any) => {
      this.nonCompliant = data;
      // console.log('nonCompliant',this.nonCompliant)
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });
  }

  getQualityCheck() {

    // this.qualityCheck = [];
    let term = this.selectedFilter;
    // term = term + (this.topChangeFilterModel != undefined ? ('qualitycheck=' + 'Opening') : '');
    
    this.isProjectLoading = true;
    this.dashBoardService.getQualityCheck(term)
    .toPromise()
    .then((data: any) => {
      this.qualityCheck = data.data;
      if (this.openingFilterModel.length <= 0) {
        this.openingFilterModel =Array.apply(null, Array(this.qualityCheck.length -1)).map(String.prototype.valueOf,"0")
      }
      if (this.bodyFilterModel.length <= 0) {
        this.bodyFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      if (this.closingFilterModel.length <= 0) {
        this.closingFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      if (this.customerFilterModel.length <= 0) {
        this.customerFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      if (this.agentFilterModel.length <= 0) {
        this.agentFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      
      // console.log('qualityCheck',this.qualityCheck)
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });
  }

  getCallSection() {
    this.isProjectLoading = true;
    this.dashBoardService.getcallSection()
      .toPromise()
      .then((data: any) => {
        this.callSection = data.data;
        // console.log(this.callSection)
        this.isProjectLoading = false;
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });

  }

  getAllProjects = () => {
    this.isProjectLoading = true;
    this.dashBoardService.getProjects(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.projects = data.projects;
        // console.log(this.projects)
        this.isProjectLoading = false;
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });

  }

  getAllCampnaigns = () => {
    this.commonService.getCampaigns(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.campaing = data.campaigns;
        // console.log(this.campaing)
      })
      .catch((err) => {
      });

  }
 

  filterCalls = (calls) => {
    let filter = document.getElementsByClassName('filter')[0]['value'];
    if(filter != "") {
      return this.calls.filter(x => x.campaign_name == filter);
    }
    return this.calls.filter(x => x);
  }

  onDateChange(dates) {
    this.start_date = dates[0];
    this.end_date = dates[1];
    this.refreshList();
    this.getAllProjects();
    this.getAllCampnaigns();
  }
  
  selectedId = [];
  allCheckIds(id) {
    this.selectedId.push(id)
  }

  navigate() {
    this.userService.setQualityData(this.selectedId);
    if (this.selectedId.length <= 0) {
      return;
    }
    let payload = {
      callid: this.selectedId
    }
    this.commonService.addCallsIdByQc(payload).toPromise().then((data) => {
      this.toastr.success('Calls Submitted For Quality Check');
      this.refreshList();
    }, (err) => {
      console.error(err);
    })
    // this.router.navigate( ['product-manager']);
    
  }

  sortColumn: string = '';
  sortOrder: string = 'asc';

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
    this.calls = this.calls.sort((a, b) => {
      const sortOrder = this.sortOrder === 'asc' ? 1 : -1;
      const aVal = a[column];
      const bVal = b[column];
      if (aVal < bVal) {
        return -1 * sortOrder;
      } else if (aVal > bVal) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }
  

}


