<div class="team-card clickable" [ngClass]="{'good': data.good_calls >= data.average_calls && data.good_calls >= data.bad_calls, 'average': data.average_calls > data.good_calls && data.average_calls > data.bad_calls, 'bad': data.bad_calls > data.average_calls && data.bad_calls > data.good_calls }" >
        <div class="infoline_profile">
            <div class="row">
                <div class="delete col-12" *ngIf="manageTeam">
                    <span (click)="deleteTeam(data.id, data.campid, data.projectid)">Delete</span>
                </div>
                <div class="profile col-7" (click)="openTeam(data.id)">
                    <div class="details">
                        <div class="name">
                        </div>
                        <div class="title">
                            {{ data.name }}
                            <div class="pro-name">
                                {{data.campaigns }}
                            </div>
                        </div>
                        <!-- <div class="role">
                            TEAM LEADER - {{data.leadername}}
                        </div> -->
                    </div>
                </div>
                <!-- <div class="attribute col-3 text-right">
                   
                    <div class="" (click)="openTeam(data.id)" >
                        <div [ngClass]="commonService.getRatingClass(data.good_calls / data.call_count * 100)" class="rating-class" >
                            {{commonService.getRating(data.good_calls / data.call_count * 100)}}
                        </div>
                    </div>
                </div> -->
                <div class="qcs-score col-5">
                    <div class="score">
                        <span>{{data.qcs |  number : '1.2-2'}}</span>
                    </div>
                    <div class="text">
                        <span>Qcs score</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="infoline_4 d-none" (click)="openTeam(data.id)">
            <div class="row">
                <div class="attribute col-6">
                    <div class="key">
                        TEAM LEADER
                    </div>
                    <div class="value">
                        {{ data.first_name }} {{ data.last_name }}
                    </div>
                </div>
                <div class="attribute col-6">
                    <div class="key">
                        CAMPAIGN
                    </div>
                    <div class="value">
                        {{ data.campaigns }}
                    </div>
                </div>
            </div>
        </div>

        <div class="infoline_1" (click)="openTeam(data.id)">
            <div class="row">
                <div class="attribute col-6">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.251 12.251">
                            <path id="ic_perm_identity_24px" d="M10.125,5.455A1.608,1.608,0,1,1,8.517,7.063a1.608,1.608,0,0,1,1.608-1.608m0,6.891c2.274,0,4.671,1.118,4.671,1.608V14.8H5.455v-.842c0-.49,2.4-1.608,4.671-1.608m0-8.346a3.063,3.063,0,1,0,3.063,3.063A3.062,3.062,0,0,0,10.125,4Zm0,6.891C8.081,10.891,4,11.917,4,13.954v2.3H16.251v-2.3C16.251,11.917,12.17,10.891,10.125,10.891Z" transform="translate(-4 -4)" fill="#909090"/>
                        </svg>     
                    </div>
                    <div class="label">
                        {{ data.agent_count }} Agents
                    </div>
                </div>
                <div class="attribute col-6 text-right">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.635" height="14.635" viewBox="0 0 14.635 14.635">
                            <path id="ic_call_24px" d="M5.943,9.334A12.317,12.317,0,0,0,11.3,14.692L13.09,12.9a.808.808,0,0,1,.829-.2,9.275,9.275,0,0,0,2.9.463.815.815,0,0,1,.813.813v2.838a.815.815,0,0,1-.813.813A13.821,13.821,0,0,1,3,3.813.815.815,0,0,1,3.813,3H6.659a.815.815,0,0,1,.813.813,9.237,9.237,0,0,0,.463,2.9.816.816,0,0,1-.2.829Z" transform="translate(-3 -3)" fill="#909090"/>
                        </svg>
                    </div>
                    <div class="label">
                        {{ data.call_count }} {{data.call_count <= 1 ? ' Call Taken' : ' Calls Taken'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="infoline_2" (click)="openTeam(data.id)">
            <div class="row">
                <div class="attribute col-12">
                    <div class="head">
                        incidents
                    </div>
                    
                </div>
                <div class="attribute col-6">
                    <div class="key">
                        Fraud incidents
                    </div>
                    <div class="value">
                        {{data.fraudcount }} 
                    </div>
                </div>
                <div class="attribute col-6">
                    <div class="key">
                        vulnerability
                    </div>
                    <div class="value">
                        {{data.vulnerabilitycount }} 
                    </div>
                </div>
                <!-- <div class="attribute col-4">
                    <div class="key">
                        Opening QCS
                    </div>
                    <div class="value">
                        {{((data.oppass / data.optotal) * 100 || 0) | number : '1.2-2' }} %
                    </div>
                </div>
                <div class="attribute col-4">
                    <div class="key">
                        Body QCS
                    </div>
                    <div class="value">
                        {{((data.bdpass / data.bdtotal) *100 || 0) | number : '1.1-1'}} %
                    </div>
                </div>
                <div class="attribute col-4">
                    <div class="key">
                        Closing QCS
                    </div>
                    <div class="value">
                        {{((data.clopass / data.clototal) *100 || 0)| number : '1.1-1'}} %
                    </div>
                </div> -->
            </div>
        </div>
    
        <div *ngIf="false" class="infoline_2" (click)="openTeam(data.id)">
            <div class="row">
                <div class="attribute col-4">
                    <div class="key-value">
                        <div class="key">
                            NPS SCORE
                        </div>
                        <div class="value">
                            {{ data.nps | number : '1.1-1' }}
                        </div>
                    </div>
                </div>
                <div class="attribute col-4">
                    <div class="key-value">
                        <div class="key">
                            CSAT SCORE
                        </div>
                        <div class="value">
                            {{ data.csat | number : '1.1-1' }}
                        </div>
                    </div>
                </div>
                <div class="attribute col-4">
                    <div class="key-value">
                        <div class="key">
                            CES SCORE
                        </div>
                        <div class="value">
                            {{ data.ces | number : '1.1-1' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="infoline_3" (click)="openTeam(data.id)">
            <div class="performance">
                Teams Performance
            </div>
            <div class="row">
                <div class="attribute col-4">
                    <div class="key-value-cont good">
                        <div class="data">
                            <div class="value good">
                                {{ data.good_calls | number }}
                            </div>
                            <div class="key">
                                PASS
                            </div>
                        </div>
                    </div>
                </div>

                <div class="attribute col-4">
                    <div class="key-value-cont average">
                        <div class="data">
                            <div class="value average">
                                {{ data.average_calls | number }}
                            </div>
                            <div class="key">
                                RECMMENDED
                            </div>
                        </div>
                    </div>
                </div>

                <div class="attribute col-4">
                    <div class="key-value-cont bad">
                        <div class="data">
                            <div class="value bad">
                                {{ data.bad_calls | number }}
                            </div>
                            <div class="key">
                                CRITICAL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>