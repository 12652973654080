import { environment } from '../../environments/environment';

export class ServerConstants {
  public static SIGNUP = environment.endpoint + 'signup';
  public static LOGIN = environment.endpoint + 'login';
  public static GET_CURRENT = environment.endpoint + 'current';
  public static GET_CALLS = environment.endpoint + 'calls';
  public static GET_SINGLE_CALLS = environment.endpoint + 'calls';
  public static GET_CALLS_COUNT = environment.endpoint + 'callcount';
  public static GET_ANALYTICS = environment.endpoint + 'analytics';
  public static GET_CURRENT_USER = environment.endpoint + 'current_user';
  public static GET_PROJECTS = environment.endpoint + 'projects';
  public static GET_CAMPAIGNS = environment.endpoint + 'campaigns';

}
