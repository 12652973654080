import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
interface Agent {
  "sno": number,
  "id": number,
  "agentname": string,
  "callscount": number,
  "passed": number,
  "npspercent": number,
  "csatpercent": number,
  "cespercent": number,
  "wpm": number,
  "health": string,
  "duration": number
}
@Component({
  selector: 'cxr-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss']
})
export class AgentCardComponent implements OnInit {

  @Input()
  data: any
  constructor(
    private router: Router,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  openAgent(id) {
    this.router.navigateByUrl('/agent/' + id);
  }

}
