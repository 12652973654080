import { Injectable, Inject } from '@angular/core';

import { ServerConstants } from '../services/serverconstants.service';
import { RestClient } from '../services/restclient.service';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})




export class BackendService {

  private endDate = moment();
  private startDate = moment("2020-09-01");

  constructor(private restClient: RestClient) {}

  signup(payload){
    return this.restClient.post(ServerConstants.SIGNUP, payload);
  }

  login(payload){
    return this.restClient.post(ServerConstants.LOGIN, payload);
  }

  getCurrent(){
    return this.restClient.get(ServerConstants.GET_CURRENT);
  }

  getCallsList(term, start_date, end_date){
    return this.restClient.get(ServerConstants.GET_CALLS + '?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD') + term);
  }

  getSingleCallsById(id){
    return this.restClient.get(ServerConstants.GET_SINGLE_CALLS + '/' + id);
  }

  getCallsCount(){
    return this.restClient.get(ServerConstants.GET_CALLS_COUNT);
  }

  getAnalytics( start_date, end_date){
    return this.restClient.get(ServerConstants.GET_ANALYTICS + '?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }

  getCurrentUser(){
    return this.restClient.get(ServerConstants.GET_CURRENT_USER);
  }

}
