import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service'
import * as moment from 'moment';

// const BASE_URL = environment.endpoint + 'api/';
const BASE_URL = environment.endpoint;


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpClient: HttpClient, private commonService: CommonService
  ) { }

  /**
   * 
   * @param timeframe : today,last7days and so one
   */

  public getCallStats(term, start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'analytics?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD') + term);
  }

  public getProjects(start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'projects?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }


  public getAdminProjects() {
    return this.httpClient.get(BASE_URL + 'projects');
  }

  public getAgents() {
    const dateObj = this.commonService.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'agents');
  }

  public getSingleAgents(id) {
    const dateObj = this.commonService.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'sigleteamsagent/' +id);
  }

  public getTeams() {
    const dateObj = this.commonService.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'teams?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  }

  public getcallSection() {
    return this.httpClient.get(BASE_URL + 'callsections');
  }

  public getNonCompliantCall(term, start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'noncompliantcall?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD') + term);
  }

  public getQualityCheck(term) {
    return this.httpClient.get(BASE_URL + 'qualitycheck/' + term);
  }

  public getPastPerformance() {
    return this.httpClient.get(BASE_URL + 'pastperformance' );
  }

  public postBulkCreateAgent(payload) {
    return this.httpClient.post(BASE_URL + 'bulkagent' , payload);
  }

  public postSingleCreateAgent(payload) {
    return this.httpClient.post(BASE_URL + 'newagent' , payload);
  }

  public postSorageConnection(payload) {
    return this.httpClient.post(BASE_URL + 'connectiondo' , payload);
  }

  public postfolderInfoAdd(payload) {
    return this.httpClient.post(BASE_URL + 'adddataintblfromcloud' , payload);
  }

  public postListfolder(payload) {
    return this.httpClient.post(BASE_URL + 'dofolder' , payload);
  }

  public postCallReletion(payload) {
    return this.httpClient.post(BASE_URL + 'callrelationwithagent' , payload);
  }

}
