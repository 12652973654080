<cxr-dashboard-edit-overlay></cxr-dashboard-edit-overlay>

<div class="row no-margin top-space">
  <div class="main-content container-fluid">

    <div class="welcome-section">
      <div class="row">
        <div class="col-sm-8">
          <div class="text">
            Welcome to CX Review.
            <!-- You are viewing date for -->
            <!-- <a href="javascript:void(0)" class="weektag">month</a> -->
            <!-- <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
            [(ngModel)]="dateRange" [selectMode]="'range'" 
            [owlDateTime]="daterange"> -->
            <!-- <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time> -->
           <div class="text downtext" style="display: none;">
              Viewing :
              <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
              [(ngModel)]="dateRange" [selectMode]="'range'" 
              [owlDateTime]="daterange">
              <a href="javascript:void(0)" [owlDateTimeTrigger]="daterange" class="weektag">Previous Week</a>
              <a href="javascript:void(0)" [owlDateTimeTrigger]="daterange"  class="weektag">Following Week</a>
              <!-- <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time> -->
              <owl-date-time [pickerType]="'calendar'" #daterange [showSecondsTimer]="true"></owl-date-time>

            </div>
          </div>
        </div>

        <div class="col-sm-4 right-part"  style="display: none;">
          <button class="stnd-bg-btn">ADD NEW REPORTS</button>
        </div>

      </div>
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12 text-right d-none" >
          <button style="margin-right: 15px;" (click)="onClickEdit()" class="stnd-bg-btn" >
            {{isDashboardEditable ? 'Save' : 'Edit'}}
          </button>
          <button *ngIf="!isDashboardEditable" (click)="openEditDashboardOverlay('customizableDashboard')" class="stnd-bg-btn" >
            Add widget
          </button>
        </div>
      </div>
      <div class="row" id="items" [ngClass]="{'edit': isDashboardEditable}" >
        <div *ngFor="let widget of dashBoardWidgets;let i = index" class="" [ngClass]="getWidgetSizeClass(widget)" >
          <img class="move-icon active" src="/assets/images/Drag Button Active.svg">
          <img class="move-icon inactive" src="/assets/images/Drag Button Inactive.svg">

          <img *ngIf=
          "isDashboardEditable" (click)="onRemoveWidget(widget, i)" class="close-icon" src="/assets/icons/icons8-macos-close-64.png">

          <!-- Performance widget -->                    
          <div *ngIf="widget.type == constants.PERFORMANCE_WIDGET && projects.length > 0 " [ngTemplateOutlet]="performanceWidget" [ngTemplateOutletContext]="{widget: widget}"></div>
          <!-- Performance widget -->


          <!-- sentiment distribution widget -->           
          <div *ngIf="widget.type == constants.SENTIMENT_SCORE_DISTRIBUTION" [ngTemplateOutlet]="sentimentDistributionWidget" [ngTemplateOutletContext]="{widget: widget}"></div>
          <!-- sentiment distribution widget -->

          <!-- sentiment widget -->           
          <div *ngIf="widget.type == constants.SENTIMENT_SCORE_CALLS" [ngTemplateOutlet]="sentimentScoreWidget" [ngTemplateOutletContext]="{widget: widget}"></div>
          <!-- sentiment widget -->

          <!-- Voice widget -->
          <div *ngIf="widget.type == constants.VOICE_WIDGET" [ngTemplateOutlet]="voiceWidget"></div>
          <!-- Voice widget -->

          <!-- Campaign widget -->
          <div *ngIf="widget.type == constants.CAMPAIGN_WIDGET" [ngTemplateOutlet]="campaignWidget"></div>
          <!-- Campaign widget -->

          <!-- Project widget -->
          <div *ngIf="widget.type == constants.PROJECT_WIDGET" [ngTemplateOutlet]="projectWidget"></div>
          <!-- Project widget -->

          <!-- Team widget -->
          <div *ngIf="widget.type == constants.TEAM_WIDGET" [ngTemplateOutlet]="teamsWidget"></div>
          <!-- Project widget -->

          <!-- Word cloud start -->
          <div class="" *ngIf="widget.type == constants.WORD_CLOUDE_WIDGET" >
            <div class="performance-container">
              <div class="title mb-0">
                {{widget.displayName || widget.name}}
              </div>

              <cxr-word-cloud *ngIf="widget.wordData != undefined && widget.wordData.length > 0" (onClick)="onClickWordCloud($event, widget)" [data]="widget.wordData" ></cxr-word-cloud>

            </div>
          </div>
          <!-- Word cloud end -->

          <!-- Team overview widget start -->
          <div *ngIf="widget.type == constants.TEAM_OVERVIEW_WIDGET" [ngTemplateOutlet]="teamOverviewWidget"></div>
          <!-- Team overview widget end -->

          <!-- Agent widget start -->
          <div *ngIf="widget.type == constants.AGENT_WIDGET" [ngTemplateOutlet]="agentWidget"></div>
          <!-- Team overview widget end -->

          <!-- Line up of calls start -->
          <div *ngIf="widget.type == constants.LINE_UP_OF_CALL_WIDGET" [ngTemplateOutlet]="lineUpOfCallsWidget"></div>
          <!-- Line up of calls widget end -->

          <!-- Activity tracker start -->
          <div *ngIf="widget.type == constants.ACTIVITY_TRACKER_WIDGET" [ngTemplateOutlet]="activityTrackerWidget"></div>
          <!-- Activity tracker widget end -->

        </div>
      </div>
    </div>


    <!-- Performance widget start -->
    <ng-template #performanceWidget let-widget='widget'  >
      <div class="performance-container row" *ngIf="widget.data != undefined" >
        <div class="title full-size" style="margin-bottom: 15px;" >
          {{widget.displayName || widget.name}}
        </div>
        <div class="date-picker">
          <span style="margin-right: 10px"> Select Date Range</span>
          <input placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
            [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event, widget)"
            [owlDateTime]="daterange">
          <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
        </div>

        <div class="attributes" *ngIf="!isCallStatsLoading">
          <div class="calls">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 14.635 14.635">
                <path id="ic_call_24px"
                  d="M5.943,9.334A12.317,12.317,0,0,0,11.3,14.692L13.09,12.9a.808.808,0,0,1,.829-.2,9.275,9.275,0,0,0,2.9.463.815.815,0,0,1,.813.813v2.838a.815.815,0,0,1-.813.813A13.821,13.821,0,0,1,3,3.813.815.815,0,0,1,3.813,3H6.659a.815.815,0,0,1,.813.813,9.237,9.237,0,0,0,.463,2.9.816.816,0,0,1-.2.829Z"
                  transform="translate(-3 -3)" fill="#909090" />
              </svg>
            </div>
            <div class="values">
              <div class="value">
                {{ widget.data.totalcallstoday }}
              </div>
              <div class="key">
                Calls Taken
              </div>
            </div>
          </div>
          <div class="ratio">
            <!-- <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 38.5 38.5">
                <path id="Red_Warning" data-name="Red Warning"
                  d="M21.25,2A19.25,19.25,0,1,0,40.5,21.25,19.257,19.257,0,0,0,21.25,2Zm1.925,28.875h-3.85v-3.85h3.85Zm0-7.7h-3.85V11.625h3.85Z"
                  transform="translate(-2 -2)" fill="#c01c1c" />
              </svg>
            </div>
            <div class="values">
              <div class="value">
                {{ widget.data.totalfailedcallstoday || 0 }}/{{ widget.data.totalpasscallstoday || 0 }}
              </div>
              <div class="key">
                Fail/Pass
              </div>
            </div> -->
            <div class="value">
              <div class="digit green">
                <span>{{ widget.data.totalpasscallstoday || 0 }}</span>
              </div>
              <div class="text">
                <span>pass</span>
              </div>
            </div>
            <div class="bordervr"></div>
            <div class="value">
              <div class="digit orenge">
                <span>{{ widget.data.totalworningcallstoday || 0 }}</span>
              </div>
              <div class="text">
                <span>warning</span>
              </div>
            </div>
            <div class="bordervr"></div>
            <div class="value">
              <div class="digit red">
                <span> {{widget.data.totalfailedcallstoday || 0}}</span>
              </div>
              <div class="text">
                <span>fail</span>
              </div>
            </div>
          </div>
          <div class="quality">
            <div class="values">
              <div style="font-size: 25px" class="value">
                {{ (widget.data.averagecallqualityscoretoday*100 || 0) | number:'1.0-0' }} %
              </div>
              <div style="font-size: 18px" class="key">
                QCS score
              </div>
            </div>
          </div>
        </div>
        <div class="performance-graph" style="height:240px" *ngIf="!isCallStatsLoading" >
          <!-- Graph starts here -->

          <!-- TODO if not working add this [view]="view" -->
          <ngx-charts-bar-vertical-stacked  [scheme]="colorScheme" [results]="widget.data.multi"
            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel" [animations]="animations" [barPadding]="15" [showGridLines]="false"
            [roundDomains]="true" (select)="onGraphCallsSelect($event, widget)" >
          </ngx-charts-bar-vertical-stacked>
          <!-- Graph ends here -->
        </div>

        <div class="main-content" *ngIf="isCallStatsLoading">
          <cxr-loading></cxr-loading>
        </div>
        
        <div class="dropdowns">
          <!-- <div class="dropdown">
            <select class="form-select btn btn-secondary" aria-label="Default select example">
              <option value="1" class="dropdown-item" >Dates: September 2022</option>
              <option value="2" class="dropdown-item">Dates: September 2022</option>
              <option value="3" class="dropdown-item">Dates: September 2022</option>
              <option value="4" class="dropdown-item">Dates: September 2023</option>
            </select>
          </div>
          <div class="dropdown">
            <select class="form-select btn btn-secondary" aria-label="Default select example">
              <option value="1" class="dropdown-item" >Complance status :All</option>
              <option value="2" class="dropdown-item">Complance status :All</option>
              <option value="3" class="dropdown-item">Complance status :All</option>
              <option value="4" class="dropdown-item">Complance status :All</option>
            </select>
          </div> -->
          <div class="dropdown">
            <!-- <select [(ngModel)]="projectFilterModel"  (change)="onProjectChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
              <option [value]="''" hidden class="dropdown-item" >Projects</option>
              <option [value]="i.id" *ngFor="let i of projects">{{i.name}}</option>
            </select> -->

            <ng-select class="ng-select" (change)="onProjectChange($event)" [items]="projects"
            [clearable]="false"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="projectFilterModel" placeholder = "Projects">
            </ng-select>

          </div>
          <div class="dropdown">
            <!-- <select [(ngModel)]="campaignsFilterModel" (change)="onCampaignChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
              <option hidden [value]="''" class="dropdown-item" >Campaigns</option>
              <option [value]="i.id" *ngFor="let i of campaigns">{{i.name}}</option>
            </select> -->

            <ng-select class="ng-select" (change)="onCampaignChange($event)" [items]="campaigns"
            [clearable]="false"
            bindLabel="name" 
            bindValue="id"
            [(ngModel)]="campaignsFilterModel" placeholder = "campaigns">
          </ng-select>
          </div>
          <div class="dropdown">
            <!-- <select  [(ngModel)]="openingFilterModel" (change)="onOpeningChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
              <option hidden [value]="''" class="dropdown-item" >Opening</option>
              <option [value]="i.checkname" *ngFor="let i of qualityCheckOpening">{{i.checkname}}</option>
            </select> -->

            <ng-select class="ng-select" (change)="onOpeningChange()" [items]="qualityCheckOpening"
              [multiple]="true"
              [clearable]="false"
              bindLabel="checkname"
              bindValue="checkname"
              [(ngModel)]="openingFilterModel" placeholder = "Opening">
            </ng-select>
          </div>
         
          <div class="dropdown">
            <!-- <select [(ngModel)]="bodyFilterModel" (change)="onBodyChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
              <option hidden [value]="''" class="dropdown-item" >Body</option>
              <option [value]="i.checkname" *ngFor="let i of qualityCheckBody">{{i.checkname}}</option>
            </select> -->
            <ng-select class="ng-select" (change)="onBodyChange()" [items]="qualityCheckBody"
              [multiple]="true"
              [clearable]="false"
              bindLabel="checkname"
              bindValue="checkname"
              [(ngModel)]="bodyFilterModel" placeholder = "Body">
            </ng-select>
          </div>
          <div class="dropdown">
            <!-- <select [(ngModel)]="closingFilterModel" (change)="onClosingChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
              <option hidden [value]="''" class="dropdown-item" >Closeing</option>
              <option [value]="i.checkname" *ngFor="let i of qualityCheckCloseing">{{i.checkname}}</option>
            </select> -->
            <ng-select class="ng-select" (change)="onClosingChange()" [items]="qualityCheckCloseing"
            [multiple]="true"
            [clearable]="false"
            bindLabel="checkname"
            bindValue="checkname"
            [(ngModel)]="closingFilterModel" placeholder = "Closing">
          </ng-select>
          </div>
          <!-- <div class="dropdown">
            <select class="form-select btn btn-secondary" aria-label="Default select example">
              <option value="1" class="dropdown-item" >Call importance: high, medium</option>
              <option value="2" class="dropdown-item">Call importance: high, medium</option>
              <option value="3" class="dropdown-item">Call importance: high, medium</option>
              <option value="4" class="dropdown-item">Call importance: high, medium</option>
            </select>
          </div>
          <div class="dropdown">
            <select class="form-select btn btn-secondary" aria-label="Default select example">
              <option value="1" class="dropdown-item" >Call: opening</option>
              <option value="2" class="dropdown-item">Call: opening</option>
              <option value="3" class="dropdown-item">Call: opening</option>
              <option value="4" class="dropdown-item">Call: opening</option>
            </select>
          </div>
          <div class="dropdown">
            <select class="form-select btn btn-secondary" aria-label="Default select example">
              <option value="1" class="dropdown-item" >Compliance types: ID, Disclosure, RPC, Mini-Mira…</option>
              <option value="2" class="dropdown-item">Compliance types: ID, Disclosure, RPC, Mini-Mira…</option>
              <option value="3" class="dropdown-item">Compliance types: ID, Disclosure, RPC, Mini-Mira…</option>
              <option value="4" class="dropdown-item">Compliance types: ID, Disclosure, RPC, Mini-Mira…</option>
            </select>
          </div> -->
        </div>
      </div>
      <div class="selected" *ngIf="allFilterCheck">
        SELECTED CHECKS : {{allFilterCheck}}
      </div>
    </ng-template>
    <!-- Performance widget end -->


    <!-- Sentiment distribution start -->
    <ng-template #sentimentDistributionWidget let-widget='widget' >
      <div class="performance-container row">
        <div class="segment" style="width: 100%">
          <div class="title" style="width: 100%;" >
            <div>
              {{widget.displayName || widget.name}}
            </div>
            <div class="graph" *ngIf="widget.results != undefined && widget.results[0].series != undefined && widget.results[0].series.length > 0" style="width: 100%;margin-top: 15px;height: 240px;" >
              <!-- [view]="view" -->
              <ngx-charts-area-chart-stacked
              [scheme]="sentimentDistributionColorScheme"
              [results]="widget.results"
              [gradient]="true"
              
              [showGridLines]="false"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showXAxisLabel]="false"
              [showYAxisLabel]="false"
              [curve]="curve"
              (select)="onSentimentGraphCallsSelect($event, widget)"
              
              >
              </ngx-charts-area-chart-stacked>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Sentiment distribution widget end -->

    <!-- Sentiment score start -->
    <ng-template #sentimentScoreWidget let-widget='widget' >
      <div class="performance-container row">
        <div class="segment" style="width: 100%">
          <div class="title">
            {{widget.displayName || widget.name}}
          </div>

          <div class="table-container">
            <div class="table slim-scrollbar">
              <table>
                <tr>
                  <th>Call id</th>
                  <!-- <th>Time</th> -->
                  <th>Duration</th>
                  <th>Sentiment Score</th>
                  <th>Quality Score</th>
                  <th>Action</th>
                </tr>
                <tr *ngFor="let call of widget.data">
                  <td>
                    {{call.id}}
                  </td>
                  <td>
                    {{call.duration}}
                  </td>
                  <td>
                    {{call.sentiment_score}}
                  </td>
                  <td>
                    {{call.quality_score}}
                  </td>
                  <td class="action-button">
                    <a style="color: #909090;text-decoration: underline;cursor: pointer;" [routerLink]="'/call/' + call.id">View</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Sentiment score widget end -->

    <!-- Voice widget start -->
    <ng-template #voiceWidget>
      <div class="performance-container">
        <div class="title">
          Voice of the customer
        </div>
        <div class="graph">
          <canvas baseChart [datasets]="barChartDataProject" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType"></canvas>
        </div>
      </div>
    </ng-template>
    <!-- Voice widget end -->

    <!-- Campaign widget start -->
    <ng-template #campaignWidget>
      <div class="performance-container">
        <div class="title mb-0">
          Campaign overview
        </div>
        <ng-select [(ngModel)]="campaignsFilter" class="filter" [items]="projects" bindLabel="name" bindValue="name" placeholder="Select project" >
        </ng-select>
        <div class="campaign-holder" *ngIf="!isCampaignLoading" >
          <div class="text-muted text-center" *ngIf="campaigns.length == 0">No Campaigns found</div>
          <div class="row no-margin">
            <div *ngFor="let campaign of campaigns | search:'projectname':campaignsFilter" [ngClass]="getCampaignsCardSizeClass(constants.CAMPAIGN_WIDGET)">
              <cxr-campaign [data]="campaign"
                routerLink="/campaign/{{campaign.id}}" ></cxr-campaign>
            </div>
          </div>
        </div>
        <div class="main-content" *ngIf="isCampaignLoading">
          <cxr-loading></cxr-loading>
        </div>
      </div>
    </ng-template>
    <!-- Campaign widget end -->

    <!-- Project widget start -->
    <ng-template #projectWidget>
      <div class="performance-container">
        <div class="title mb-0">
          Project Overview
        </div>
        <div class="campaign-holder" *ngIf="!isProjectLoading" >
          <div class="text-muted text-center" *ngIf="projects.length == 0">No Projects found</div>

          <div class="row no-margin">
            <div *ngFor="let project of projects" [ngClass]="getCampaignsCardSizeClass(constants.PROJECT_WIDGET)">
              <cxr-campaign [data]="project" (click)="openCampaign(project.id)">
              </cxr-campaign>
            </div>
          </div>
        </div>

        <div class="main-content" *ngIf="isProjectLoading">
          <cxr-loading></cxr-loading>
        </div>
      </div>
    </ng-template>
    <!-- Project widget end -->

    <!-- Performance widget start -->
    <ng-template #teamOverviewWidget>
      <div class="performance-container row">
        <div class="title" style="margin-bottom: 0" >
          TEAM OVERVIEW
        </div>

        <div *ngIf="isOverviewLoading" class="main-content">
          <cxr-loading></cxr-loading>
        </div>

        <div *ngIf="!isOverviewLoading" style="width: 100%;" >
          <cxr-overview [data]="cxrTeamOverviewData"></cxr-overview>
        </div>
      </div>
    </ng-template>
    <!-- Performance widget end -->

    <!-- Teams widget start -->
    <ng-template #teamsWidget>
      <div class="performance-container">
        <div class="title">
          Teams
        </div>
        <div class="campaign-holder" style="margin: 0;" *ngIf="!isTeamListLoading" >
          <div class="text-muted text-center" *ngIf="campaigns.length == 0">No teams found</div>
          <div class="table-container">
            <div class="table slim-scrollbar">
              <table>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Leader</th>
                  <th>Agent</th>
                  <th>Call</th>
                  <th>Warning</th>
                  <th>Action</th>
                </tr>
                <tr *ngFor="let team of teams">
                  <td>
                    {{team.id}}
                  </td>
                  <td>
                    {{team.name}}
                  </td>
                  <td>{{team.leadername}}</td>
                  <td>
                    {{team.agents_count}}
                  </td>
                  <td>{{team.calls_count}}</td>
                  <td>
                    <span [ngClass]="commonService.getRatingClass(team.quality_score)" >
                      {{commonService.getRating(team.quality_score)}}
                    </span>
                  </td>
                  <td class="action-button">
                    <a style="color: #909090;text-decoration: underline;cursor: pointer;" [routerLink]="'/team/' + team.id">View</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="main-content" *ngIf="isTeamListLoading">
          <cxr-loading></cxr-loading>
        </div>
      </div>
    </ng-template>
    <!-- Teams widget end -->

    <!-- Agent widget start -->
    <ng-template #agentWidget>
      <div class="performance-container">
          
        <div class="title">
          Agents
        </div>
        <ng-select [(ngModel)]="agentsFilter" class="filter" [items]="teams" bindLabel="name" bindValue="name" placeholder="Select team" >
        </ng-select>

        <div class="campaign-holder" style="margin: 0" *ngIf="!agentsLoading" >
          <div class="text-muted text-center" *ngIf="campaigns.length == 0">No agents found</div>
          <div class="table-container">
            <div class="table slim-scrollbar">
              <table>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Team</th>
                  <th>Call</th>
                  <th>Warning</th>
                  <th>Action</th>
                </tr>
                <tr *ngFor="let agent of agents | search:'name':agentsFilter">
                  <td>
                    {{agent.id}}
                  </td>
                  <td>
                    {{agent.first_name}} {{agent.last_name}}
                  </td>
                  <td>{{agent.name}}</td>
                  <td>{{agent.calls_count}}</td>
                  <td>
                    <span [ngClass]="commonService.getRatingClass(agent.quality_score)" >
                      {{commonService.getRating(agent.quality_score)}}
                    </span>
                  </td>
                  <td class="action-button">
                    <a style="color: #909090;text-decoration: underline;cursor: pointer;" [routerLink]="'/agent/' + agent.id">View</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="main-content" *ngIf="agentsLoading">
          <cxr-loading></cxr-loading>
        </div>
      </div>
    </ng-template>
    <!-- Teams widget end -->

    <!-- Line up of calls widget start -->
    <ng-template #lineUpOfCallsWidget>
      <div class="performance-container">
          
        <div class="title">
          LINE UP OF CALLS
        </div>

        <div class="campaign-holder" style="margin: 0" *ngIf="!agentsLoading" >
          <div class="text-muted text-center" *ngIf="lineUpCalls.length == 0">No calls found</div>
          <div class="table-container">
            <div class="table slim-scrollbar">
              <table>
                <tr>
                  <th>Call ID</th>
                  <th>Agent Name</th>
                  <th>quality score</th>
                  <th></th>
                </tr>
                <tr *ngFor="let agent of lineUpCalls">
                  <td>
                    {{agent.id}}
                  </td>
                  <td>
                    {{agent.agent_name}}
                  </td>
                  <td>
                    <span [ngClass]="commonService.getRatingClass(agent.quality_score)" >
                      {{agent.quality_score}}/100
                    </span>
                  </td>
                  <td class="action-button">
                    <a style="color: #909090;text-decoration: underline;cursor: pointer;" [routerLink]="'/agent/' + agent.id">View</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="main-content" *ngIf="agentsLoading">
          <cxr-loading></cxr-loading>
        </div>
      </div>
    </ng-template>
    <!-- Line up of calls widget end -->

    <!-- Activity tracker widget start -->
    <ng-template #activityTrackerWidget>
      <div class="performance-container">
        <div class="title">
          ACTIVITY TRACKER
        </div>

        <div class="campaign-holder" style="margin: 0" *ngIf="!agentsLoading" >
          <div class="text-muted text-center" *ngIf="notifications.length == 0">No activity found</div>
          <div class="table-container activity-tracker">
            <div class="table slim-scrollbar">
              <table>
                <tr>
                </tr>
                <tr *ngFor="let noti of notifications">
                  <td>
                    <div *ngIf="noti.type == constants.COMMENT_LIKE">
                      {{noti.notify_by_user.first_name}} {{noti.notify_by_user.last_name}} has read your <a [routerLink]="'/call/' + noti.callid">comment</a>.
                    </div>
                    <div *ngIf="noti.type == constants.POST_COMMENT">
                      {{noti.notify_by_user.first_name}} {{noti.notify_by_user.last_name}} has left a new <a [routerLink]="'/call/' + noti.callid">comment</a> for you.
                    </div>
                    <div class="time" >{{commonService.timeSince(noti.timestamp)}}</div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
    <!-- Activity tracker widget end -->

    <!-- slider -->
    <div class="col-sm-12" *ngIf="projects.length > 0 ">
      <div class="slider">
        <div class="header">
          <h6>
            PAST PERFORMANCE
          </h6>
        </div>
        <div *ngIf="sliderData.length > 0">
          <owl-carousel [options]="carouselOptions" id="carousel"
          class="owl-theme owl-performance-carousel " [carouselClasses]="['owl-carousel', 'owl-category-carousel', 'owl-theme', 'sliding']">
          <div class="item" *ngFor="let item of sliderData;let i = index " [ngClass]="{'active' : i>=2, 'active2' : i>=4}" (click)="sendCall(item)">
            <a class="item-div" >
              <div class="">
                <span class="date">{{item.start_date | date}} To {{item.end_date | date}}</span>
              </div>
              <div class="images">
                <div class="number good">
                  <span>{{(item.calls[0].calls_pass / item.calls[0].call_total) * 100 | number : '1.2-2'}} %</span>
                </div>
                <div class="text">
                  <span>QCS SCORE </span>
                </div>
              </div>
              <!-- <div *ngFor="let prc of item.qualityCheck">
                <div class="second-img">
                  <div class="images" *ngFor="let key of prc | keys">
                    <div >
                      <div class="text">
                        <span>{{key}}</span>
                      </div>
                      <div class="number">
                        <span>{{prc[key]}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div>
                <div class="second-img">
                  <div class="images">
                    <div >
                      <div class="text">
                        <span>Opening QCS</span>
                      </div>
                      <div class="number">
                        <span>{{(item.qualityCheck[0].oppass / item.qualityCheck[0].optotatal) * 100 | roundUp}} %</span>
                      </div>
                    </div>
                  </div>
                  <div class="images">
                    <div >
                      <div class="text">
                        <span>Body QCS</span>
                      </div>
                      <div class="number">
                        <span>{{(item.qualityCheck[0].bdpass / item.qualityCheck[0].bdtotatal) * 100 | roundUp}} %</span>
                      </div>
                    </div>
                  </div>
                  <div class="images">
                    <div >
                      <div class="text">
                        <span>Closing QCS</span>
                      </div>
                      <div class="number">
                        <span>{{(item.qualityCheck[0].clopass / item.qualityCheck[0].clototatal) * 100 | roundUp }} %</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="last">
                <div class="leftside">
                  <span><i class="fa fa-user-o" aria-hidden="true"></i>{{item.calls[0].agents}} agents
                  </span>
                </div>
                <div class="leftside">
                  <span><i class="fa fa-phone" aria-hidden="true"></i>{{item.call_taken}} calls taken
                  </span>
                </div>
              </div>
            </a>
          </div>
        </owl-carousel>
        </div>
      </div>
    </div>

    <!-- empty project -->
    <div class="col-sm-12" *ngIf="emptyScreen">
      <div class="emptyScreen">
        <div class="row">
          <div class="col-sm-12">
            <div class="data">
              <div class="block">
                <div class="image">
                  <img src="../../../assets/svgs/Icon metro-traffic-cone.svg" >
                </div>
                <div class="text_data">
                  <div class="head">
                    <span>No project created</span>
                  </div>
                  <div class="para">
                    <p>Create your first project for analysis.</p>
                  </div>
                  <div class="button">
                    <button type="button" class="btn next" routerLink="/admin/project/details">create project</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
