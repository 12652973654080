export class Constants {

  /*Dashboard widget types*/
  public static PERFORMANCE_WIDGET = 1;
  public static PROJECT_WIDGET = 2;
  public static CAMPAIGN_WIDGET = 3;
  public static VOICE_WIDGET = 4;
  public static WORD_CLOUDE_WIDGET = 5;
  public static PROJECT_OVERVIEW_WIDGET = 6;
  public static TEAM_OVERVIEW_WIDGET = 7;
  public static AGENT_WIDGET = 8;
  public static TEAM_WIDGET = 9;
  public static SENTIMENT_SCORE_CALLS = 10;
  public static SENTIMENT_SCORE_DISTRIBUTION = 11;
  public static ACTIVITY_TRACKER_WIDGET = 12;
  public static LINE_UP_OF_CALL_WIDGET = 13;
  public static CUSTOM_WIDGET = 14;



  /*Events*/
  public static SENT_APP_THEME = 1;
  public static SENT_DASHBOARD_EVENT = 2;
  public static SENT_DASHBOARD_EVENT_TO_EDIT = 3;
  public static OPEN_CONFIRMATION_OVERLAY = 4;

  /*Notification*/
  public static COMMENT_LIKE = 1;
  public static POST_COMMENT = 2;


  /*User roles*/
  public static ROOT = "root";
  public static ADMIN = "admin";
  public static TEAM_LEAD = "team leader";
  public static QUALITY_MANAGER = "quality manager";
  public static AGENT = "agent";
  public static CALL_CENTER_MANAGER = "CC manager";
  static SOMETHING_WENT_WRONG: any;
}
