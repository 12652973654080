<div class="row no-margin top-space">
    <!-- <div class="main-content" *ngIf="agentName == null">
        <cxr-loading></cxr-loading>
    </div> -->
    <div class="main-content">

        <div class="welcome-section">
          <div class="row">
            <div class="col-sm-8">
              <div class="text">
                Welcome to CX Review.
                <!-- You are viewing date for -->
                <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
                [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
                [owlDateTime]="daterange">
                <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
              </div>
            </div>
            <div class="col-sm-4 right-part d-none">
                <a class="anchor-btn" href="javascript:void(0)">
                    See Campaign Configuration
                </a>
            </div>
          </div>
        </div>

        <div class="row no-margin">
            <div class="list-container">
                <div class="row">
                    <div class="col-12">
                        <div class="back-link" (click)="back()">
                            <div class="icon">
                                <i class="fa fa-arrow-left"></i>
                            </div>
                            <div class="message">
                                Back to all agents
                            </div>
                        </div>

                        <div class="title">
                            {{agentName}}
                        </div>
                    </div>
                    <div *ngIf="false" class="col-8 text-right">
                        <div style="display: inline-block;" >
                            <div class="profile">
                                <div class="image" [title]="agentName.split(' ')[0] + ' ' + agentName.split(' ')[1]" [ngClass]="'circle-' + agentId%4" >
                                    <span *ngIf="agentName != undefined" >
                                        {{( agentName.split(' ')[0] != undefined
                                        && agentName.split(' ')[0] != null
                                        && agentName.split(' ')[0] != '')
                                        ? agentName.split(' ')[0].charAt(0) : ''}}

                                        {{(agentName.split(' ')[1] != undefined
                                        && agentName.split(' ')[1] != null
                                        && agentName.split(' ')[1] != '')
                                        ? agentName.split(' ')[1].charAt(0) : ''}}
                                    </span>
                                </div>
                                <div class="details">
                                    <div class="name" [title]="agentName">
                                        {{ agentName }}
                                    </div>
                                    <div class="role">
                                        {{agentData.role | titlecase}}#{{agentId}}
                                    </div>
                                    <div class="role team">{{agentData.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="title">
                    {{agentName}}
                </div> -->

                <div class="row" *ngIf="false" >
                    <div class="col-12">
                        <div class="overall-message" >OVERALL AGENT PERFORMANCE OVER TIME(Quality over Time)</div>
                        <div class="performance-graph" *ngIf="performance[0].series != undefined && performance[0].series.length > 0" >
                            <!-- [view]="view" -->
                            <ngx-charts-area-chart-stacked
                            [scheme]="colorScheme"
                            [results]="performance"
                            [gradient]="true"
                            [showGridLines]="false"
                            [xAxis]="true"
                            [yAxis]="true"
                            [legend]="false"
                            [showXAxisLabel]="false"
                            [showYAxisLabel]="false"
                            [curve]="curve"
                            (select)="onGraphCallsSelect($event)"
                            >
                          </ngx-charts-area-chart-stacked>
                        </div>
                    </div>
                </div>

                <div class="list">
                    <div class="list-header">
                        <div class="header-name">
                            Call Id
                        </div>
                        <div class="header-name">
                            Agent Name
                        </div>
                        <div class="header-name">
                            Campaign
                        </div>
                        <div class="header-name">
                            Call Duration
                        </div>
                        <div class="header-name">
                            Quality
                        </div>
                        <!-- <div class="header-name">
                            NPS
                        </div>
                        <div class="header-name">
                            CSAT
                        </div>
                        <div class="header-name">
                            CES
                        </div> -->
                        <div class="header-name">
                            Sentiment
                        </div>
                        <!-- <div class="header-name">
                            Verification
                        </div> -->
                        <div class="header-name">
                            Agent V.I
                        </div>
                        <div class="header-name">
                            Customer V.I
                        </div>
                        <div class="header-name">
                            Actions
                        </div>
                    </div>
                    <div class="list-row" routerLink="/call/{{call.id}}" *ngFor="let call of callData">
                        <div class="attribute ">
                            {{ call.id }}
                        </div>
                        <div class="attribute">
                            {{ call.user.first_name }}  {{ call.user.last_name }}
                        </div>
                        <div class="attribute">
                            {{ call.campaign.name }}
                        </div>
                        <div class="attribute">
                            {{ toHHMMSS(call.duration) }}
                        </div>
                        <div class="attribute" *ngIf="call.quality_checks.length > 0" [ngClass]="{'red': (call.quality_checks[0].pass_checks / call.quality_checks[0].total_checks) * 100  < 45, 
                        'orange': (call.quality_checks[0].pass_checks / call.quality_checks[0].total_checks) * 100 > 45 && (call.quality_checks[0].pass_checks / call.quality_checks[0].total_checks) * 100 < 75,'green' : (call.quality_checks[0].pass_checks / call.quality_checks[0].total_checks) * 100 > 75}" >
                            <!-- {{ call.quality_score }}/100 -->
                            {{(call.quality_checks[0].pass_checks / call.quality_checks[0].total_checks )* 100 | number : '1.2-2'}}%
                        </div>
                       
                        <!-- <div class="attribute">
                            {{ call.nps }}
                        </div>
                        <div class="attribute">
                            {{ call.csat }}
                        </div>
                        <div class="attribute">
                            {{ call.ces }}
                        </div> -->
                        <div class="attribute">
                            <!-- {{ call.sentiment_score }} -->
                            <span *ngIf="call.sentiment_score >= -0.2 && call.sentiment_score <= 0.2">Neutral</span>
                            <span *ngIf="call.sentiment_score > 0.2 ">Positive</span>
                            <span *ngIf="call.sentiment_score < -0.2">Negative</span>
                        </div>
                        <!-- <div class="attribute d-none" [ngClass]="{'green' : call.verified, 'red' : !call.verified}" >
                            {{call.verified ? 'Verified': 'Not Verified'}}
                        </div> -->
                        <div class="attribute" [ngClass]="{'green': call.agent_vi > 85,
                'orange': call.agent_vi >= 65 && call.agent_vi <= 85, 
                'red': call.agent_vi < 65}" >
                            {{call.agent_vi  | number : '1.2-2'}}%
                        </div>
                        <div class="attribute" [ngClass]="{'green': call.customer_vi > 85,
                'orange': call.customer_vi >= 65 && call.customer_vi <= 85, 
                'red': call.customer_vi < 65}" >
                            {{call.customer_vi  | number : '1.2-2'}}%
                        </div>
                        <div class="attribute link">
                            View
                        </div>
                    </div>


                </div>

                <!-- Pagination start -->
                <nav aria-label="Page navigation example" *ngIf="false" >
                  <ul class="pagination justify-content-center custom-style">
                    <li class="page-item"><a class="page-link active" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                  </ul>
                </nav>
                <!-- Pagination end -->

            </div>
            <div class="right-container">

                <div class="row">
                    <div class="col-sm-10">
                        <div class="graph-container" *ngIf="false" >
                            <div class="title">
                                <div style="margin-bottom:35px">
                                    AGENT'S PERFORMANCE
                                </div>
                            </div>
                            <!-- <div class="date-picker">
                                <input  placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate" [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)" [owlDateTime]="daterange">
                                <owl-date-time  [pickerType]="'calendar'" #daterange></owl-date-time>
                            </div> -->

                            <div class="row no-margin">
                                <div class="graph">
                                    <ngx-charts-bar-vertical [view]="[400,500]" [barPadding]="35" [scheme]="colorScheme"
                                        [results]="checks" [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                        [showYAxisLabel]="false">
                                    </ngx-charts-bar-vertical>
                                </div>
                                <div class="metrics">
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.calls}}
                                        </div>
                                        <div class="graph-key">
                                            Calls Taken
                                        </div>
                                    </div>
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.nps.toFixed(1)}}
                                        </div>
                                        <!-- <div class="icon red">
                                            <i class="fa fa-arrow-down"></i>
                                        </div> -->
                                        <div class="graph-key">
                                            NPS
                                        </div>
                                    </div>
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.csat.toFixed(1)}}
                                        </div>
                                        <!-- <div class="icon green">
                                            <i class="fa fa-arrow-up"></i>
                                        </div> -->
                                        <div class="graph-key">
                                            CSAT
                                        </div>
                                    </div>
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.ces.toFixed(1)}}
                                        </div>
                                        <!-- <div class="icon green">
                                            <i class="fa fa-arrow-up"></i>
                                        </div> -->
                                        <div class="graph-key">
                                            CES
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="agent-performance">
                            <div class="title">
                                QUALITY CHECKS
                            </div>

                            <div class="row">
                              <div class="col-sm-6" *ngFor="let quality of checks">
                                <div class="quality-stamp good">
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="label">
                                        LABEL
                                      </div>
                                      <div class="value">
                                        {{quality.name}}
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="label">
                                        SCORE
                                      </div>
                                      <div class="value">
                                        {{quality.value}}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>

                        <div class="agent-performance d-none" *ngIf="false">
                            <div class="title">
                                CALL BREAKUP
                            </div>

                            <div class="attribute">
                                <div class="key-value-cont good">
                                    <div class="data">
                                        <div class="key">
                                            Good Calls - {{agentData.good_calls | number}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute">
                                <div class="key-value-cont average">
                                    <div class="data">
                                        <div class="key">
                                            Average Calls - {{agentData.average_calls | number}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="attribute">
                                <div class="key-value-cont bad">
                                    <div class="data">
                                        <div class="key">
                                            Bad Calls - {{ agentData.bad_calls | number}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="agent-performance d-none">
                            <div class="title" style="margin-bottom: 0px;" >
                                VERIFICATION OVERVIEW
                            </div>

                            <div class="row" style="align-items: center;" >
                                <div class="col-sm-3">
                                    <ngx-charts-pie-chart
                                        [scheme]="colorSchemeDonut" [results]="verificationCallsSeries"
                                        [margins]="[0,0,0,0]" [doughnut]="true" [legendTitle]="'Hi the'"
                                        [labelFormatting]="pieChartLabel.bind(this, series)" [animations]="false">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="col-sm-1"></div>
                                <div class="col-sm-7">
                                    <div class="verification-text">
                                        <div class="key">Good Job!</div>
                                        <div class="value">
                                            This Agent has been verified as the speaker in over {{verifiedCalls}}% of their calls.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="agent-performance d-none">
                            <div class="title" style="margin-bottom: 0px;" >
                                WORD CLOUD
                            </div>

                            <div class="row" >
                                <div class="col-sm-10">
                                    <cxr-word-cloud *ngIf="wordData.length > 0" [data]="wordData" (onClick)="onClickWordCloud($event)" ></cxr-word-cloud>
                                </div>
                            </div>
                        </div>

                        <div class="round-graph" *ngIf=false >
                            <!-- <div class="title">
                                <div style="margin-bottom:35px">
                                    COMPLIANCE
                                </div>
                            </div> -->
                            <!-- <div class="row no-margin">
                                <div class="graph">
                                    <ngx-charts-pie-chart [view]="[200, 130]" [scheme]="{domain: ['#909090', '#347C73']}"
                                        [results]="series" [margins]="[0,0,0,0]" [doughnut]="true" [legendTitle]="'Hi the'"
                                        [labelFormatting]="pieChartLabel.bind(this, series)" [animations]="false">
                                    </ngx-charts-pie-chart>
                                    <div class="label">
                                        <div class="value">
                                            {{pass}}/{{fail}}
                                        </div>
                                        <div class="key">
                                            PASS/FAIL
                                        </div>
                                    </div>
                                </div>
                                <div class="metric">
                                    <div class="top">
                                        Warning
                                    </div>
                                    <div class="mid" [ngClass]="commonService.getRatingClass(overview.quality_score)">
                                        {{commonService.getRating(overview.quality_score)}}
                                    </div>
                                    <div class="bottom">
                                        Base level warning
                                    </div>
                                </div>
                            </div> -->
                            <div class="title breakup-title">
                                <div>
                                    CALL BREAKUP
                                </div>
                            </div>
                            <div class="call-breakup" >
                                <div class="attribute">
                                    <div class="key-value good" >
                                        <div class="value">
                                            {{ agentData.good_calls }}
                                        </div>
                                        <div class="key">
                                            Good calls
                                        </div>
                                    </div>
                                </div>
                                <div class="attribute">
                                    <div class="key-value average" >
                                        <div class="value">
                                            {{ agentData.average_calls }}
                                        </div>
                                        <div class="key">
                                            Average calls
                                        </div>
                                    </div>
                                </div>
                                <div class="attribute">
                                    <div class="key-value bad" >
                                        <div class="value">
                                            {{ agentData.bad_calls }}
                                        </div>
                                        <div class="key">
                                            Bad calls
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments d-none" *ngIf="false">
                            <div class="title">
                                COMMENTS
                            </div>
                            <div class="list" *ngIf="comments.length>0">
                                <div class="comment my-4" *ngFor="let comment of comments" (click)="scrollTo(comment._id)">
                                    <div class="row m-0" style="align-items: center; margin-bottom: 5px !important;" >
                                        <div class="image mr-2" [title]="agentName.split(' ')[0] + ' ' + agentName.split(' ')[1]" [ngClass]="'circle-' + agentId%4" >
                                            <span *ngIf="agentName != undefined" >
                                                {{( agentName.split(' ')[0] != undefined
                                                && agentName.split(' ')[0] != null
                                                && agentName.split(' ')[0] != '')
                                                ? agentName.split(' ')[0].charAt(0) : ''}}

                                                {{(agentName.split(' ')[1] != undefined
                                                && agentName.split(' ')[1] != null
                                                && agentName.split(' ')[1] != '')
                                                ? agentName.split(' ')[1].charAt(0) : ''}}
                                            </span>
                                        </div>
                                        <div class="name">
                                            {{agentName}}
                                        </div>
                                    </div>
                                    <div class="sentence m-0">
                                        {{ comment.comment }}
                                    </div>
                                </div>

                            </div>
                            <div class="list text-muted text-center" *ngIf="comments.length==0">
                                No Comments
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>