import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { TopComponent } from './components/top/top.component';
import { SideComponent } from './components/side/side.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { TableComponent } from './components/table/table.component';
import { CallsComponent } from './components/calls/calls.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProductManagerComponent } from './components/product-manager/product-manager.component';

import { ChartsModule } from 'ng2-charts';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamCardComponent } from './components/team-card/team-card.component';
import { AgentCardComponent } from './components/agent-card/agent-card.component';
import { AgentsComponent } from './components/agents/agents.component';
import { CampaigndetailsComponent } from './components/campaigndetails/campaigndetails.component';
import { TeamdetailsComponent } from './components/teamdetails/teamdetails.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OverviewComponent } from './components/overview/overview.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgentdetailsComponent } from './components/agentdetails/agentdetails.component';
import { CalldetailsComponent } from './components/calldetails/calldetails.component';
import { AdminComponent } from './components/admin/admin.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { VulnerabilityIndexComponent } from './components/vulnerability-index/vulnerability-index.component';
import { VulnerabilityDrawerComponent } from './components/vulnerability-drawer/vulnerability-drawer.component';
import { KeywordsComponent } from './components/keywords/keywords.component';
import { WordCloudComponent } from './components/word-cloud/word-cloud.component';
import { DashboardEditOverlayComponent } from './components/dashboard-edit-overlay/dashboard-edit-overlay.component';
import {MatChipsModule} from '@angular/material/chips';
import { SearchPipe } from './pipes/search.pipe';
import { AutoSizeDirective } from './directive/auto-size.directive';
import { OwlModule } from 'ngx-owl-carousel';
import { CallCopyComponent } from './components/call-copy/call-copy.component';
import { QualitynewuiComponent } from './components/qualitynewui/qualitynewui.component';
import { KeysPipe } from './services/keys.pipe';
import { QcmanagerComponent } from './components/qcmanager/qcmanager.component';
import { TranscriptsearchComponent } from './components/transcriptsearch/transcriptsearch.component';
import { TreestructureComponent } from './components/treestructure/treestructure.component';
import { ForgotpassComponent } from './components/forgotpass/forgotpass.component';
import { ToastrModule } from 'ngx-toastr';

// import { SidebarComponent } from './components/samplewhite/sidebar/sidebar.component';
// import { TopbarComponent } from './components/samplewhite/topbar/topbar.component';
// import { DashboardComponentSample } from './components/samplewhite/dashboard/dashboard.component';
// import { CampaigncardComponent } from './components/samplewhite/campaigncard/campaigncard.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TopComponent,
    SideComponent,
    DashboardComponent,
    CampaignComponent,
    TableComponent,
    CallsComponent,
    ProductManagerComponent,
    CampaignsComponent,
    TeamsComponent,
    TeamCardComponent,
    AgentCardComponent,
    AgentsComponent,
    CampaigndetailsComponent,
    TeamdetailsComponent,
    AgentdetailsComponent,
    CalldetailsComponent,
    AdminComponent,
    ProjectsComponent,
    VulnerabilityIndexComponent,
    VulnerabilityDrawerComponent,
    KeywordsComponent,
    OverviewComponent,
    // SidebarComponent,
    // TopbarComponent,
    // DashboardComponentSample,
    // CampaigncardComponent,
    LoadingComponent,
    WordCloudComponent,
    DashboardEditOverlayComponent,
    SearchPipe,
    AutoSizeDirective,
    CallCopyComponent,
    QualitynewuiComponent,
    KeysPipe,
    QcmanagerComponent,
    TranscriptsearchComponent,
    TreestructureComponent,
    ForgotpassComponent
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    TagCloudModule,
    NgxAudioPlayerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatChipsModule,
    OwlModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true,
      positionClass: 'toast-bottom-right'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
